import ReactGA from "react-ga4";

import "./App.css";
import Main from "./components/Main";

// initialize google analytics
ReactGA.initialize("G-Z495MLWWF4");

function App() {
  return <Main />;
}

export default App;
