import { useEffect } from "react";

function useEscapeHandler(handler) {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handler();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });
}

export default useEscapeHandler;
