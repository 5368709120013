import { createPortal } from "react-dom";
import { motion } from "framer-motion";

import useOutsideClick from "../hooks/useOutsideClick";
import useEscapeHandler from "../hooks/useEscapeHandler.js";

import close from "../assets/close.svg";

function Drawer({ handleClose, handleScrollClose, categories }) {
  useEscapeHandler(() => {
    handleClose();
  });

  const wrapperRef = useOutsideClick(() => {
    handleClose();
  });

  return createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="overlay"
    >
      <motion.div
        initial={{ x: "100vw", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: "100vw", opacity: 0 }}
        transition={{ type: "just", duration: 0.3, ease: "easeOut" }}
        className="drawer-container"
        ref={wrapperRef}
      >
        <div className="drawer-header">
          <h2>Categories</h2>
          <img src={close} alt="X" onClick={handleClose} />
        </div>
        <div className="drawer-body">
          {categories.map((category, index) => {
            return (
              <p key={category} onClick={() => handleScrollClose(index)}>
                {category}
              </p>
            );
          })}
        </div>
      </motion.div>
    </motion.div>,
    document.getElementById("drawer-root")
  );
}

export default Drawer;
