import { motion } from "framer-motion";

import Image from "./Image";

function Categories(props) {
  const { resources, itemEls } = props;

  const categories = Object.keys(resources);
  return (
    <div>
      {categories.map((category, index) => {
        return (
          <div
            ref={(element) => (itemEls.current[index] = element)}
            key={category}
            className="category"
          >
            <h2>{category}</h2>
            <div className="card-container">
              {resources[category].map((website) => {
                return (
                  <motion.div
                    whileHover={{
                      scale: 1.08,
                      boxShadow: "0px 3px 6px #00000080",
                    }}
                    key={website.name}
                    className="card"
                    onClick={() => {
                      window.open(
                        `${website.link}?ref=designdevresources.js.org`,
                        "_blank"
                      );
                    }}
                  >
                    <Image url={website.link} />
                    <h3>{website.name}</h3>
                    <p>{website.description}</p>
                  </motion.div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Categories;
