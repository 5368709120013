import React from "react";
import { motion } from "framer-motion";

const Skeleton = () => {
  return (
    <>
      <motion.div className="card skeleton-wrapper">
        <motion.div
          className="skeleton-image"
          animate={{ opacity: [0.5, 1] }}
          transition={{
            repeat: Infinity,
            duration: 0.5,
          }}
        />
        <motion.div
          className="skeleton-title"
          animate={{ opacity: [0.5, 1] }}
          transition={{
            repeat: Infinity,
            duration: 0.5,
          }}
        />
        <motion.div
          className="skeleton-line"
          animate={{ opacity: [0.5, 1] }}
          transition={{
            repeat: Infinity,
            duration: 0.5,
          }}
        />
        <motion.div
          className="skeleton-line"
          animate={{ opacity: [0.5, 1] }}
          transition={{
            repeat: Infinity,
            duration: 0.5,
          }}
        />
        <motion.div
          className="skeleton-line"
          animate={{ opacity: [0.5, 1] }}
          transition={{
            repeat: Infinity,
            duration: 0.5,
          }}
        />
      </motion.div>
    </>
  );
};

export default Skeleton;
