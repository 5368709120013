import { useEffect, useRef } from "react";

function useOutsideClick(handler) {
  const ref = useRef(null);
  useEffect(() => {
    function validator(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener("mousedown", validator);
    return () => {
      document.removeEventListener("mousedown", validator);
    };
  });
  return ref;
}

export default useOutsideClick;
