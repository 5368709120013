function Image({ url }) {
  const pos1 = url.indexOf("/");
  const pos2 = url.indexOf("/", pos1 + 1);
  let newUrl = url.substr(pos2 + 1);
  if (newUrl.includes("/")) {
    const pos3 = newUrl.indexOf("/");
    newUrl = newUrl.substr(0, pos3);
  }
  const icon = `https://icons.duckduckgo.com/ip3/${newUrl}.ico`;
  return <img src={icon} alt="" />;
}

export default Image;
