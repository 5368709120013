import { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";

import logo from "../assets/logo.svg";
import search from "../assets/search.svg";
import edit from "../assets/edit.svg";
import menu from "../assets/menu.svg";
import inputclose from "../assets/inputclose.svg";
import Categories from "./Categories";
import Image from "./Image";
import Drawer from "./Drawer";
import Skeleton from "./Skeleton";
import useOutsideClick from "../hooks/useOutsideClick";
import useEscapeHandler from "../hooks/useEscapeHandler.js";

function Main() {
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [input, setInput] = useState("");
  const [resources, setResources] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeItem, setActiveItem] = useState(-1);
  const windowOffset = useRef(0);
  const section = useRef();
  const itemEls = useRef([]);
  const suggestionEls = useRef([]);
  const categories = Object.keys(resources);

  // get all the resources
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const { data } = await axios.get(
        `https://rawcdn.githack.com/phanendraguptha/design-dev-resources/master/resources.json`
      );
      setResources(data);
      setLoading(false);
    };
    getData();

    return () => {
      setResources({});
    };
  }, []);

  // clear suggestion when input is null
  useEffect(() => {
    if (input === "") {
      setSuggestions([]);
      suggestionEls.current = [];
    }
  }, [input]);

  // disable parent scroll
  useEffect(() => {
    const scrollBarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    if (openDrawer === true) {
      windowOffset.current = window.scrollY;
      document
        .getElementById("root")
        .setAttribute(
          "style",
          `position:fixed; top: -${windowOffset.current}px; left:0, right:0; margin-right:${scrollBarWidth}px`
        );
    } else if (openDrawer === false) {
      document.getElementById("root").setAttribute("style", "");
      window.scrollTo(0, windowOffset.current);
      if (section.current >= 0) {
        itemEls.current[section.current].scrollIntoView({ behavior: "smooth" });
        section.current = undefined;
      }
    }
  }, [openDrawer]);

  // scroll to selected sugesstion
  useEffect(() => {
    if (activeItem >= 0) {
      const index = suggestionEls.current.findIndex((element) => {
        if (element) {
          return element.classList.contains("active");
        }
        return 0;
      });
      const top = suggestionEls.current[index].offsetTop;
      suggestionEls.current[index].parentNode.scrollTo({
        top,
        behavior: "smooth",
      });
    }
  }, [activeItem]);

  useEscapeHandler(() => {
    setVisible(false);
  });

  const wrapperRef = useOutsideClick(() => {
    setVisible(false);
  });

  // handle input change
  const handleChange = (e) => {
    setInput(e.target.value);
    setVisible(true);
    setActiveItem(-1);

    const categories = Object.keys(resources);

    let searchables = [];
    categories.forEach((categorie) => {
      searchables = [...searchables, ...resources[categorie]];
    });

    const filteredCharacters = searchables.filter((searchable) => {
      return (
        searchable.name.toLowerCase().includes(input.toLowerCase()) ||
        searchable.categorie.toLowerCase().includes(input.toLowerCase())
      );
    });
    setSuggestions(filteredCharacters);
  };

  // drawer close
  const handleClose = () => {
    setOpenDrawer(false);
  };

  // go to the categorie
  const handleScrollClose = async (id) => {
    section.current = id;
    setOpenDrawer(false);
  };

  const navigate = (e) => {
    if (e.code === "ArrowDown") {
      if (visible) {
        setActiveItem((c) => (c < suggestions.length - 1 ? c + 1 : c));
      }
    }
    if (e.key === "ArrowUp") {
      if (visible) {
        setActiveItem((c) => (c > 0 ? c - 1 : 0));
      }
    }
    if (e.key === "Enter") {
      window.open(
        `${suggestions[activeItem].link}?ref=designdevresources.js.org`,
        "_blank"
      );
    }
  };

  return (
    <div>
      <section className="top-container">
        <nav className="nav">
          <img src={logo} alt="logo" />
          Design Dev Resources
          <div className="menu">
            <img
              src={menu}
              alt=""
              onClick={() => setOpenDrawer(true)}
              className="menu"
            />
          </div>
        </nav>
        <h2>
          Focus on what's more important!
          <br />A one-stop shop for all designers and developers. Let's say
          goodbye to all those rusty bookmark lists and add value to your time.
        </h2>
        <div className="wrapper">
          <div className="search-input" ref={wrapperRef}>
            <input
              type="text"
              autoFocus
              placeholder="Search tools here..."
              value={input}
              onChange={handleChange}
              onKeyDown={navigate}
              onClick={() => {
                setVisible(true);
              }}
            />
            <AnimateSharedLayout>
              <motion.div layout className="autocom-box">
                {visible && (
                  <Fragment>
                    {suggestions.map((suggestion, index) => {
                      return (
                        <motion.li
                          layout
                          key={suggestion.name}
                          className={activeItem === index ? "active" : ""}
                          // tabIndex="0"
                          ref={(element) => suggestionEls.current.push(element)}
                          onClick={() => {
                            window.open(
                              `${suggestion.link}?ref=designdevresources.js.org`,
                              "_blank"
                            );
                          }}
                        >
                          <Image url={suggestion.link} />
                          {suggestion.name}
                        </motion.li>
                      );
                    })}
                  </Fragment>
                )}
              </motion.div>
            </AnimateSharedLayout>
            <img src={search} className="icon" alt="" />
            {input && (
              <img
                src={inputclose}
                className="close"
                alt="X"
                onClick={() => setInput("")}
              />
            )}
          </div>
        </div>
      </section>
      <section className="resources-container">
        {loading ? (
          <div className="card-container">
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        ) : null}
        {!loading ? (
          <>
            <Categories resources={resources} itemEls={itemEls} />
            <motion.button
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                window.open(
                  "https://github.com/phanendraguptha/design-dev-resources/edit/master/resources.json",
                  "_blank"
                );
              }}
            >
              <img src={edit} alt="" /> Edit this page
            </motion.button>
          </>
        ) : null}
      </section>
      <footer>
        Made with ❤️️ by{" "}
        <a
          href="https://phanendraguptha.github.io/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Phanendra Guptha
        </a>
      </footer>
      <AnimatePresence>
        {openDrawer && (
          <Drawer
            categories={categories}
            handleClose={handleClose}
            handleScrollClose={handleScrollClose}
          />
        )}
      </AnimatePresence>
    </div>
  );
}
export default Main;
